import React, {useState} from 'react';
import TabMenu from "./TabMenu";
import axdImg from "../assets/images/database-zap-1.svg"
import webProImg from "../assets/images/cloud-cog.svg"
import dmProImg from "../assets/images/cpu-3.svg"
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css"

const Main = () => {
    const [activeSlug, setActiveSlug] = useState("dm")

    const swaggerUrls = {
        edx: process.env.REACT_APP_EDX_API_URL || "https://learn.iblai.app/api-docs/",
        web: process.env.REACT_APP_WEB_API_URL || "https://web.iblai.app/rest-api/schema/",
        dm:  process.env.REACT_APP_DM_API_URL || "https://base.manager.iblai.app/api/docs/schema/",
    }

    return (
        <div className="main-content">
            <div className="inner-content">
                <div className="w-layout-grid tabs-header-grid">
                    <TabMenu setActiveSlug={setActiveSlug} activeSlug={activeSlug} slug={"dm"} name={"IBL DM PRO APIs"} icon={dmProImg}/>
                    {/*<TabMenu setActiveSlug={setActiveSlug} activeSlug={activeSlug} slug={"web"} name={"IBL WEB PRO APIs"} icon={webProImg}/>*/}
                    <TabMenu setActiveSlug={setActiveSlug} activeSlug={activeSlug} slug={"edx"} name={"IBL EDX PRO APIs"} icon={axdImg}/>
                </div>
                <div className="w-layout-hflex tabs-content">
                    <SwaggerUI url={swaggerUrls?.[activeSlug]} />
                </div>
            </div>
        </div>
    );
};

export default Main;
