//import SwaggerUI from "swagger-ui-react";
//import "swagger-ui-react/swagger-ui.css"
import Header from "./layouts/Header";
import Main from "./layouts/Main";
import ThriveVideoModal from "./layouts/ThriveVideoModal";


function App() {
    return (
        <>
            <ThriveVideoModal/>
            <Header />
            <Main/>
        </>
    );
}

export default App;
