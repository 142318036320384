import React from 'react';

const TabMenu = ({icon, name, slug, activeSlug, setActiveSlug}) => {

    const handleTabClick = () => {
        setActiveSlug(slug)
    }
    return (
        <div onClick={() => handleTabClick()} id="w-node-d3226005-b906-4a7e-880b-3f0bf6c8f6e1-b90afeb7"
             className={`w-layout-vflex tab-menu ${slug === activeSlug ? "active" : ""}`}><img
            src={icon} loading="lazy" alt="" className="tab-menu-icon"/>
            <h4 className={`tab-menu-title`}>{name}</h4>
            <div className={`tab-active-arrow w-embed ${slug !== activeSlug ? "not-visible" : ""}`}>
                <div className="arrow-up"></div>
            </div>
        </div>
    );
};

export default TabMenu;