import React, {useEffect, useState} from "react";
import logoImg from "../assets/images/IBL-ai-platform-white.png"
import searchIconImg from "../assets/images/search.svg"
import githubImg from "../assets/images/github-1-1.png"
import huggingfaceImg from "../assets/images/huggingface_logo-noborder.svg"
import googleImg from "../assets/images/search-google-p-500.png"
import awsImg from "../assets/images/aws-logo-c-1.png"
import closeImg from "../assets/images/x.svg"
import chevronImg from "../assets/images/chevron-1.png"
import menuImg from "../assets/images/menu.svg"
import edxLogoImg from "../assets/images/Open-edX-horz-WHITE.svg"
import $ from "jquery"
import "../utils/highlight"
import {useDebounce} from "../utils/useDebounce";

const Header = () => {

    const [searchValue, setSearchValue] = useState('')

    const handleSearchHighLight =()=>{
        $('.opblock-summary-path a span').removeHighlight();
        if(!!searchValue && String(searchValue).length > 2){
            $('.opblock-summary-path a span').highlight(searchValue)
        }
    }

    const handleSearchBarChange = (e) =>{
        if(!e){
            return
        }
        setSearchValue( e?.target?.value || "")
    }

    const handleSearchBarOnSubmit = (e) => {
        e.preventDefault()
        return false
    }

    const handleExtraJSEvents = () =>{

        $('.mobile-menu-layout .mobile-menu-closer').on('click', function(){
            $('.mobile-menu-layout').fadeOut('fast')
        })
        $('.mobile-menu-opener').on('click', function(){
            $('.mobile-menu-layout').fadeIn('fast')
        })
        $('.mobile-menu-toggler').on("click", function(){
            const parent = $(this).parent()
            parent.find('.mobile-submenu-container').fadeToggle('fast')
        })

        $('.thrive-video-link').on('click', function(e){
            e.preventDefault()
            $('.modal-video.thrive-video').css({display:"flex"})
        })
    }

    /*useEffect(() => {
        handleExtraJSEvents()
    }, []);*/

    useDebounce(handleExtraJSEvents, 500, [])
    useDebounce(handleSearchHighLight, 500, [searchValue])

    return (
        <section className="header">
            <div className="w-layout-hflex header-container">
                <div className="w-layout-hflex header---left-side">
                    <a href="https://ibl.ai/" className="w-inline-block"><img className="image img-whiten"
                                                                src={logoImg} alt=""
                                                                prop-attr-names="href"
                                                                prop-attr-value-href="prop-siteLogo" loading="lazy"
                                                                /></a>
                    <div className="form-block w-form">
                        <form onSubmit={handleSearchBarOnSubmit} id="wf-form-app-search" name="wf-form-app-search"
                              data-name="app-search"
                              className="form" data-wf-page-id="65a9700082bd63a6b90afeb7"
                              action="/"
                              data-wf-element-id="8830b3aa-6a29-910f-faf8-272b8ed02355"><img width="15" height="15"
                                                                                             src={searchIconImg}
                                                                                             alt=""
                                                                                             className="image-2 img-whiten"/><input
                            className="search-bar-input w-input" maxLength="256" value={searchValue}
                            onChange={handleSearchBarChange}
                            data-name="App Search" placeholder="Search..." type="text" id="app-search" required=""/>
                        </form>
                        <div className="w-form-done">
                            <div>Thank you! Your submission has been received!</div>
                        </div>
                        <div className="w-form-fail">
                            <div>Oops! Something went wrong while submitting the form.</div>
                        </div>
                    </div>
                </div>
                <div className="w-layout-hflex header---right-side">
                    <a href="/" target="_blank" className="link">API</a>
                    <div data-hover="false" data-delay="0" className="menu-element-with-subm hidden w-dropdown">
                        <div className="menu-element w-dropdown-toggle">
                            <div className="icon w-icon-dropdown-toggle"></div>
                            <div>API</div>
                        </div>
                        <nav className="submenu-list w-dropdown-list">
                            <div className="div-block-9">
                                <div className="div-block-10">
                                    <h1 className="heading-4">API</h1>
                                    <a href="https://api.ibl.ai/" target="_blank"
                                       className="submenu-link w-dropdown-link">Docs</a>
                                </div>
                            </div>
                        </nav>
                    </div>
                    <div data-hover="false" data-delay="0" className="menu-element-with-subm w-dropdown">
                        <div className="menu-element w-dropdown-toggle">
                            <div className="icon w-icon-dropdown-toggle"></div>
                            <div>About</div>
                        </div>
                        <nav className="submenu-list w-dropdown-list">
                            <div className="div-block-9">
                                <div className="div-block-10">
                                    <h1 className="heading-4">About</h1>
                                    <a href="https://ibl.ai/about.html" target="_blank"
                                       className="submenu-link w-dropdown-link">About IBL</a>
                                    <a href="https://ibleducation.com/clients" target="_blank"
                                       className="submenu-link w-dropdown-link">Testimonials</a>
                                    <a href="#"
                                       className="submenu-link w-dropdown-link thrive-video-link">Thrive (Video)</a>
                                </div>
                            </div>
                        </nav>
                    </div>
                    <a href="https://github.com/iblai" target="_blank" className="w-inline-block"><img
                        src={githubImg} alt=""
                        className="github-zoom"/></a>
                    <a href="https://huggingface.co/iblai" target="_blank" className="w-inline-block"><img
                        src={huggingfaceImg} alt=""
                        className="github-zoom more-bigger"/></a>
                    <a href="https://cloud.google.com/blog/topics/public-sector/ibl-educations-genai-based-chat-mentor-google"
                       target="_blank" className="w-inline-block"><img src={googleImg}
                                                                       alt="" className="google-zoom"/></a>
                    <a href="https://ibleducation.com/aws" target="_blank" className="w-inline-block"><img
                        src={awsImg}
                        alt="" className="aws bigger"/></a>
                    <a href="https://openedx.org/marketplace/ibl-education/" target="_blank"
                       className="mobile-menu-logo w-inline-block"><img src={edxLogoImg}
                                                                        loading="lazy" alt=""
                                                                        className="image-15 aws open-edx-img bigger"/></a>
                    <a href="https://ibleducation.com/contact-us" target="_blank"
                       className="header-contact w-inline-block">
                        <div className="text-block-3">Contact Us</div>
                    </a>
                </div>
                <a href="#" className="mobile-menu-opener w-inline-block"><img src={menuImg}
                                                                               alt=""
                                                                               className="image-14 img-whiten"/></a>
            </div>
            <div className="mobile-menu-layout">
                <div className="mobile-menu-container">
                    <div className="mobile-menu-area">
                        <div className="mobile-menu-close-container"><img src={closeImg} alt=""
                                                                          className="mobile-menu-closer"/></div>
                        <div className="mobile-menu-element">
                            <a href="https://api.ibl.ai/" className="mobile-menu-toggler no-toggle w-inline-block">
                                <div className="text-block-9">API</div>
                            </a>
                        </div>
                        <div className="mobile-menu-element">
                            <a href="#" className="mobile-menu-toggler w-inline-block">
                                <div className="text-block-9">About</div>
                                <img src={chevronImg} alt="" className="image-12"/>
                            </a>
                            <div>
                                <div className="w-layout-vflex mobile-submenu-container">
                                    <a href="https://ibl.ai/about.html" target="_blank"
                                       className="mobile-submenu-element">About IBL</a>
                                    <a href="https://ibleducation.com/clients/" target="_blank"
                                       className="mobile-submenu-element">Testimonials</a>
                                    <a href="#"
                                       className="mobile-submenu-element thrive-video-link">Thrive (Video)</a>
                                </div>
                            </div>
                        </div>
                        <div className="w-layout-vflex mobile-menu-actions-container">
                            <a href="https://github.com/iblai" target="_blank"
                               className="mobile-menu-logo w-inline-block"><img src={githubImg}
                                                                                alt="" className="image-13"/></a>
                            <a href="https://huggingface.co/iblai" target="_blank"
                               className="mobile-menu-logo w-inline-block"><img
                                src={huggingfaceImg} alt=""
                                className="image-13 more-bigger"/></a>
                            <a href="https://cloud.google.com/blog/topics/public-sector/ibl-educations-genai-based-chat-mentor-google"
                               target="_blank" className="mobile-menu-logo w-inline-block"><img
                                src={googleImg} alt=""
                                className="image-13"/></a>
                            <a href="https://ibleducation.com/aws" target="_blank"
                               className="mobile-menu-logo w-inline-block"><img src={awsImg}
                                                                                alt="" className="image-13 aws-mobile"/></a>
                            <a href="https://openedx.org/marketplace/ibl-education/" target="_blank"
                               className="mobile-menu-logo w-inline-block"><img src={edxLogoImg}
                                                                                loading="lazy" alt=""
                                                                                className="image-15 open-edx-img bigger"/></a>
                            <a href="https://ibleducation.com/contact-us" target="_blank"
                               className="mobile-contact w-inline-block">Contact Us</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Header