import React from 'react';
import closeImg from "../assets/images/x-9.svg"
import {useDebounce} from "../utils/useDebounce";
import $ from "jquery"

const ThriveVideoModal = () => {
    const handleModalClose = () => {
        $('.modal-video-closer').on('click', function (){
            $('.modal-video.thrive-video').hide()
        })
    }

    useDebounce(handleModalClose, 500, [])
    return (
        <div className="w-layout-hflex modal-video thrive-video">
            <div className="w-layout-hflex modal-video-main-block">
                <div className="w-layout-hflex modal-video-container">
                    <div style={{paddingTop:"56.17021276595745%"}} className="modal-video-element w-video w-embed">
                        <iframe className="embedly-embed"
                                src="https://cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2F6tvHXBNGr_g%3Ffeature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3D6tvHXBNGr_g&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2F6tvHXBNGr_g%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&type=text%2Fhtml&schema=youtube"
                                width="940" height="528" scrolling="no" allowFullScreen=""
                                title="Thrive | IBL's View for a New Time"></iframe>
                    </div>
                </div>
                <img src={closeImg} loading="lazy"  alt=""
                     className="modal-video-closer"/>
            </div>
        </div>
    );
};

export default ThriveVideoModal;